<template>
  <section class="team">
    <div class="container_team" style="padding-bottom: 5rem">
      <h1 class="heading" id="team">Meet Our Team</h1>
      <p class="caption" style="padding-bottom: 3rem">Halo, Senang bisa bertemu dengan kalian, salam kenal!</p>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="adfi" alt="Adfi" />
          </div>
          <div class="info">
            <h3>Iki Adfi Nur Mohamad</h3>
            <span>Chief Executive Officer</span>
            <div class="icons">
              <a href="https://instagram.com/ini.adfi" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/iki-adfi-nur-mohamad-485b06248" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="rafdhi" alt="Rafdhi" />
          </div>
          <div class="info">
            <h3>Rafdhi Fatoni Septiyanto</h3>
            <span>Chief Operational Officer</span>
            <div class="icons">
              <a href="https://www.instagram.com/rafdhi11/" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/iki-adfi-nur-mohamad-485b06248" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="rozi" alt="Rozi" />
          </div>
          <div class="info">
            <h3>Fatchur Rozi</h3>
            <span>Chief Marketing Officer</span>
            <div class="icons">
              <a href="https://www.instagram.com/ftchrrzi10/" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/rafdhi-fatoni-1bb347260" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="linda" alt="Linda" />
          </div>
          <div class="info">
            <h3>Linda Dwi Rahmawati</h3>
            <span>Chief Finance Officer</span>
            <div class="icons">
              <a href="https://www.instagram.com/lidra_32/" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/linda-dwi-rahmawati-01375a264" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="aul" alt="Aul" />
          </div>
          <div class="info">
            <h3>Aulia Salsabila Gani</h3>
            <span>Head of Event Department</span>
            <div class="icons">
              <a href="https://www.instagram.com/auuslsbl/" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="http://www.linkedin.com/in/auliasalsabilag" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="shiva" alt="shiva" />
          </div>
          <div class="info">
            <h3>Shiva Kusumaning Tidar</h3>
            <span>Event Department</span>
            <div class="icons">
              <a href="http://instagram.com/shivood" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="fando" alt="fando" />
          </div>
          <div class="info">
            <h3>Refando Yugo Dwi D.</h3>
            <span>Head of Equipment Department</span>
            <div class="icons">
              <a href="http://instagram.com/___.ffandoo" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/refando-yugo-53a733259?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" class="fa-linkedin" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-linkedin" size="sm"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="dot" alt="rama" />
          </div>
          <div class="info">
            <h3>Rama Indra Darmawan</h3>
            <span>Equipment Department</span>
            <div class="icons">
              <a href="https://www.instagram.com/ramaindrad?igsh=Mjc3eDIwOXlkbnpp&utm_source=qr" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="roman" alt="roman" />
          </div>
          <div class="info">
            <h3>Romansyah Putra P.</h3>
            <span>Equipment Department</span>
            <div class="icons">
              <a href="http://instagram.com/urbrokenguy" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="cahya" alt="cahya" />
          </div>
          <div class="info">
            <h3>Iltizam Cahya Nugraha</h3>
            <span>Head of Creative Department</span>
            <div class="icons">
              <a href="https://www.instagram.com/cahya.nugraha_?igsh=MWQxcjZ4bzlqYTM2dw==" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="tania" alt="tania" />
          </div>
          <div class="info">
            <h3>Antania Dyah Absari</h3>
            <span>Creative Department</span>
            <div class="icons">
              <a href="https://instagram.com/an.antania" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="" class="fa-linkedin" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" size="sm" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <div class="image">
            <img :src="pras" alt="tania" />
          </div>
          <div class="info">
            <h3>Prastyo Gunawan</h3>
            <span>Creative Department</span>
            <div class="icons">
              <a href="http://instagram.com/prastyxg" class="fa-instagram" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" size="sm" /></a>
              <a href="https://www.linkedin.com/in/prastyogunawan?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" class="fa-linkedin" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-linkedin" size="sm"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import adfi from "@/assets/team/aad.jpg";
import rozi from "@/assets/team/roji.jpeg";
import rafdhi from "@/assets/team/rafdhi.jpg";
import linda from "@/assets/team/lindaw.jpg";
import aul from "@/assets/team/aulia.jpg";
import fando from "@/assets/team/fando.jpg";
import tania from "@/assets/team/tania.jpeg";
import pras from "@/assets/team/pras.jpg";
import dot from "@/assets/team/dot.jpeg";
import shiva from "@/assets/team/siva.jpg";
import cahya from "@/assets/team/cahya.jpg";
import roman from "@/assets/team/roman.jpg";

export default {
  name: "OurMission",
  props: {},

  data() {
    return {
      adfi: adfi,
      fando: fando,
      tania: tania,
      rafdhi: rafdhi,
      rozi: rozi,
      linda: linda,
      aul: aul,
      pras: pras,
      dot: dot,
      shiva: shiva,
      cahya: cahya,
      roman: roman,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Poppins&display=swap");

.team {
  text-align: center;
  background-color: var(--biru);
}

.team .heading {
  color: var(--kuning);
  font-size: 64px;
  font-weight: 800;
  font-family: "Montserrat";
  margin-top: 7rem;
  padding-top: 100px;
}

.caption {
  color: var(--putih);
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.team .row {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.team .row .card {
  height: 27rem;
  width: 20rem;
  background-color: #fff;
  text-align: center;
  margin: 2rem 2rem;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 8%;
}

.team .row .card:hover {
  transform: scale(0.9);
  cursor: pointer;
}

.team .row .card .image {
  margin: 1rem 0;
  padding-top: 2rem;
}

.team .row .card .image img {
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  border: 0.5rem solid var(--biru);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  object-fit: cover;
}

.team .row .card .info h3 {
  font-size: 18px;
  color: var(--biru);
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.team .row .card .info span {
  font-size: 16px;
  color: var(--biru);
  font-family: "Poppins", sans-serif;
}

.team .row .card .info .icons a {
  margin-top: 4rem;
  padding-top: 0 1rem;
  font-size: 2rem;
  color: #333;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.team .row .card .info .icons .fa-facebook,
.team .row .card .info .icons .fa-instagram,
.team .row .card .info .icons .fa-linkedin {
  margin: 0.001rem;
  transition: 0.5s;
}

.team .row .card .info .icons .fa-facebook:hover {
  color: #4267b2;
  text-decoration: none;
  transform: scale(0.9);
  cursor: pointer;
}

.team .row .card .info .icons .fa-instagram:hover {
  color: #c13584;
  text-decoration: none;
  transform: scale(0.9);
  cursor: pointer;
}

.team .row .card .info .icons .fa-linkedin:hover {
  color: #2867b2;
  text-decoration: none;
  transform: scale(0.9);
  cursor: pointer;
}

@media (max-width: 480px) {
  .team .heading {
    font-size: 30px;
    margin-top: 3rem;
    padding-top: 80px;
  }

  .caption {
    font-size: 16px;
    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-right: 1rem;
  }
}

/* .team .row .card::before,
.team .row .card::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 13.5rem;
  width: 13.5rem;
  z-index: -1;
}

.team .row .card::before {
  background: linear-gradient(#00bfff, var(--biru));
  top: -6rem;
  right: -8rem;
}

.team .row .card::after {
  background: var(--kuning);
  bottom: -8rem;
  left: -6rem;
} */
</style>
