import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ScheduleView from "../views/ScheduleView.vue";
import AboutDash from "../views/AboutDash.vue";
import PhotoDash from "../views/PhotoDash.vue";
import VideoDash from "../views/VideoDash.vue";
import DesignDash from "../views/DesignDash.vue";
import WebDash from "../views/WebDash.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "/about",
    component: AboutDash,
  },
  {
    path: "/photography",
    name: "/photography",
    component: PhotoDash,
  },
  {
    path: "/videography",
    name: "/videography",
    component: VideoDash,
  },
  {
    path: "/graphicsdesign",
    name: "/graphicsdesign",
    component: DesignDash,
  },
  {
    path: "/webdevelopment",
    name: "/webdevelopment",
    component: WebDash,
  },
  {
    path: "/schedule",
    name: "/schedule",
    component: ScheduleView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
