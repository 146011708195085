<template>
  <NavDash></NavDash>
  <LandingPage />
  <AboutUs />
  <OurService />
  <trackRecord> </trackRecord>
  <CarouselComp />
  <scheduleNow> </scheduleNow>
  <FAQComp />
  <footerDash></footerDash>
</template>

<script>
import NavDash from "@/components/dashboard/NavDash.vue";
import LandingPage from "@/components/home/LandingPage.vue";
import AboutUs from "@/components/home/homeAboutUs.vue";
import OurService from "@/components/home/homeOurService.vue";
import FAQComp from "@/components/home/FAQComp.vue";
import CarouselComp from "@/components/home/CarouselComp.vue";
import trackRecord from "@/components/home/trackRecord.vue";
import scheduleNow from "@/components/home/scheduleNow.vue";
import footerDash from "@/components/dashboard/footerDash.vue";

export default {
  name: "HomeView",
  components: {
    NavDash,
    LandingPage,
    AboutUs,
    OurService,
    trackRecord,
    CarouselComp,
    scheduleNow,
    FAQComp,
    footerDash,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&display=swap");
:root {
  --biru: #002855;
  --kuning: #ffd460;
  --putih: #fff;
}

#app {
  box-sizing: border-box;
  margin: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

/* Apply negative margins to the components */
NavDash,
LandingPage,
AboutUs {
  margin-top: -20px;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&display=swap");
</style>
